import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { GET_PORTFOLIOS } from "../apollo/queries/getPortfolios";
import { MANAGER_TASKS_QUERY } from "../apollo/queries/managerTasksQuery";
import { MY_TASKS_QUERY } from "../apollo/queries/myTasksQuery";
import {
  Box,
  Tabs,
  Tab,
  Badge,
  CircularProgress
} from '@mui/material';
import AssetList from "../components/AssetList/AssetList";
import { UserContext } from "../context/UserContext";
import AssignedTasks from "../components/AssignedTasks/AssignedTasks";
import AssetReferralTable from "../components/AssetReferralTable/AssetReferralTable";
import { useTheme } from '@emotion/react';
import { rgba } from "emotion-rgba";
import styled from "@emotion/styled";
import { SEEN_ALL_REFERRALS_MUTATION } from "../apollo/mutations/seenAllReferrals";
import { GET_PORTFOLIO_COUNTS } from "../apollo/queries/getPortfolioCounts";
import Typography from "../styled/Typography/Typography";

const defaultStyle = {
  height: '100%',
}

const hidden = {
  display: 'none',
  height: '100%',
}

const StyledPageContainer = styled(Box)`
  background: ${({theme}) => rgba(theme.themeColor.sectionFill, 0.6)};
  border: 1px solid ${({theme}) => theme.themeColor.sectionStroke};
  backdrop-filter: blur(20px);
  border-radius: 4px;
  margin: 11px;
`

export default function PortfolioAssets({ client }) {
  const theme = useTheme();
  const { setPortfolioID, portfolioID, setUser, user } = useContext(UserContext);

  const [numTasks, setNumTasks] = useState(0);
  const [numReferrals, setNumReferrals] = useState(null);
  const [openedReferralTab, setOpenedReferralTab] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  
  const { loading, data, error } = useQuery(GET_PORTFOLIOS);
  const { data: countData } = useQuery(GET_PORTFOLIO_COUNTS, {
    skip: !portfolioID,
    variables: { portfolioId: portfolioID }
  });
  const { loading: managerTasksLoading, data: managerTasks } = useQuery(MANAGER_TASKS_QUERY, {
    variables: { userID: user.userID },
    skip: user.userType !== "PORTFOLIO_MANAGER",
    // pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
  });

  const { loading: tasksLoading, data: tasksData } = useQuery(MY_TASKS_QUERY, {
    variables: { userID: user.userID },
    skip: user.userType === "PORTFOLIO_MANAGER",
    // pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
  });

  const [seenAllReferrals] = useMutation(SEEN_ALL_REFERRALS_MUTATION);

  useEffect(async () => {
    if (data?.getUserPortfolios?.length > 0) {
      setSelected(data.getUserPortfolios[0].id);
      setPortfolioID(data.getUserPortfolios[0].id);
    } else if (data?.getUserPortfolios?.length === 0) {
      setUser({ ...user, accDeleted: true })
    }
    if (countData?.getPortfolioCounts) {
      setNumReferrals(countData.getPortfolioCounts.referralsCount || "");
    }
  }, [data, countData]);

  if (user?.accDeleted) return (
    <Typography component="h1" variant="h3" align="center" mt={50}>
      {user.userType === "PORTFOLIO_MANAGER" ? "Your" : "Your Portfolio Manager's"} account has been deleted.
    </Typography>
  )
  if (selected === null && !error) return null;
  if (loading) return <CircularProgress />;

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
    if (openedReferralTab) {
      // user has seen the referrals
      setNumReferrals(0);
    }
  };

  const referralTableClick = async () => {
    setOpenedReferralTab(true);
    await seenAllReferrals({
      variables: {
        seenAll: true
      },
    });
  }

  return (
    <StyledPageContainer theme={theme}>
      <Tabs value={selectedTab} onChange={handleChange} >
        <Tab label="Assets" />
        <Tab label={
          <div>
            Tasks{" "}
            {numTasks > 0 && <Badge sx={{ml: 1.5, fontFamily: 'IBM Plex Mono'}} badgeContent={numTasks} color="primary" />}
          </div>
        } />
        <Tab onClick={referralTableClick} label={
          <div>
            Referrals{" "}
            {numReferrals > 0 && <Badge sx={{ml: 1.5, fontFamily: 'IBM Plex Mono'}} badgeContent={numReferrals} color="primary" />}
          </div>
        } />
      </Tabs>
      
      <Box sx={{height: '90%'}}>
        <div style={selectedTab !== 0 ? hidden : defaultStyle}><AssetList portfolioID={selected} client={client} selectedTab={selectedTab} /></div>
        <div style={selectedTab !== 1 ? hidden : defaultStyle}><AssignedTasks setNumTasks={setNumTasks} tasksData={tasksData} tasksLoading={tasksLoading} managerTasks={managerTasks} managerLoading={managerTasksLoading} selectedTab={selectedTab}/></div>
        <div style={selectedTab !== 2 ? hidden : defaultStyle}><AssetReferralTable portfolioID={selected} user={user} selectedTab={selectedTab} /></div>
      </Box>
    </StyledPageContainer>
  );
}
