import "ag-grid-enterprise";
import React, { useMemo, useState, useCallback, useEffect, useRef } from "react";
import { gql } from "@apollo/client";
import { AgGridReact } from "ag-grid-react";
import Grid from "../../styled/Grid/StyledGrid";
import GridSearch from "./GridSearch";
import ReportToolPanel from "./ReportToolPanel";
import styled from "@emotion/styled";
import { useQuery } from "@apollo/client";
import GridFilters from "./GridFilters";
import { areFiltersEqual } from "../../utilities/index";

export const GET_TABLE_FILTERS = gql`
  query findTableFilters($tableName: String!) {
    findTableFilters(tableName: $tableName) {
      id
      name
      tableName
      filters
      showToEveryone
    }
  }
`;

const Floating = styled('div')`
  position: absolute;
  bottom: 120px;
  left: 20px;
`;

const ls = window.localStorage;

const StandardGrid = (props) => {
  const { tableName, components, gridOptions, floatingButton, selectedTab } = props;
  const { columnDefs } = gridOptions;

  const gridRef = useRef();
  const filterRef = useRef();

  const [allFilters, setAllFilters] = useState([]);
  const [currFilter, setCurrFilter] = useState({});
  const [canSaveFilter, setCanSaveFilter] = useState(false);
  const [canDeleteFilter, setCanDeleteFilter] = useState(false);

  const { data } = useQuery(GET_TABLE_FILTERS, {
    skip: !tableName,
    variables: { tableName },
  });

  useEffect(() => {
    if (data?.findTableFilters) {
      const sameTable = data.findTableFilters.filter((filter) => filter.tableName === tableName);
      const precannedFilters = sameTable.filter((filter) => filter.showToEveryone);
      const usermade = sameTable.filter((filter) => !filter.showToEveryone);

      setAllFilters([...precannedFilters, ...usermade]);
    }
  }, [data]);

  gridOptions.onFilterChanged = useCallback(() => {
    const filters = gridRef.current.api.getFilterModel();
    const hasFilters = filters && Object.keys(filters).length > 0;
    const sameFilters = filterRef.current
      && areFiltersEqual(filterRef.current.filters, filters);
    const hasAdditionalFilters = hasFilters && !sameFilters;

    if (filterRef.current?.filters && sameFilters) {
      // Selected dropdown, no filters applied
      setCurrFilter({});
      setCanSaveFilter(false);
      filterRef.current.showToEveryone
        ? setCanDeleteFilter(false)
        : setCanDeleteFilter(true);
    } else if (filterRef.current?.filters && hasAdditionalFilters) {
      // Selected dropdown, adding more filters
      setCurrFilter(filters);
      setCanSaveFilter(true);
      setCanDeleteFilter(false);
    } else if (!filterRef.current?.filters && hasFilters) {
      // Didn't select dropdown, adding filters
      setCurrFilter(filters);
      setCanSaveFilter(true);
      setCanDeleteFilter(false);
    }
  }, [currFilter, selectedTab]);

  const icons = useMemo(() => {
    return {
      sortAscending: '<i class="fa-solid fa-caret-up"></i>',
      sortDescending: '<i class="fa-solid fa-caret-down"></i>',
      search: `<i class="fa-solid fa-search"></i>`,
      fileIcon: `<i class="fa-solid fa-file"></i>`,
    };
  });
  
  columnDefs[columnDefs.length - 1].headerComponent = 'GridOptions';
  columnDefs[columnDefs.length - 1].headerComponentParams = {
    tableName
  };
  const gridComponents = { ...components };

  const onColumnVisible = () => {
    const columnState = JSON.stringify(gridRef.current.columnApi.getColumnState());
    if (window) window[tableName] = columnState;
    ls.setItem(tableName, columnState);

    if (tableName === 'assetList')
      gridRef.current.columnApi.autoSizeAllColumns();
    else
      gridRef.current.api.sizeColumnsToFit();
  }

  const onFirstDataRendered = () => {
    const columnState = gridRef.current.columnApi.getColumnState();
    if (columnState) {
      gridRef.current.columnApi.applyColumnState({
        state: columnState,
        applyOrder: true,
      });
      if (tableName === 'assetList') {
        gridRef.current.columnApi.autoSizeAllColumns();
      } else {
        gridRef.current.api.sizeColumnsToFit();
      }
    }
  };

  const sideBar = useMemo(() => {
    const res = {
      toolPanels: [
        {
          id: 'search',
          labelDefault: 'Search',
          labelKey: 'search',
          iconKey: 'search',
          toolPanel: GridSearch,
          minWidth: 250,
          maxWidth: 350,
          width: 300,
          toolPanelParams: {
            cellClass: 'search-panel',
          }
        },
      ],
      position: 'right',
      defaultToolPanel: false,
    };
    if (tableName === 'assetList') {
      res.toolPanels.push({
        id: 'reports',
        labelDefault: 'Reports',
        labelKey: 'reports',
        iconKey: 'fileIcon',
        toolPanel: ReportToolPanel,
        minWidth: 250,
        maxWidth: 350,
        width: 300,
      })
    }

    if (tableName === 'assignedTaskFlows' || tableName === 'taskFlowTemplatesList')
      return res;
    else {
      res.toolPanels.splice(1, 0, {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        minWidth: 250,
        maxWidth: 350,
        width: 300,
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          toolPanelSuppressValues: true,
        }
      },
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
        minWidth: 250,
        maxWidth: 250,
        width: 300,
      });
      return res;
    }
    
  }, []);

  columnDefs.forEach(def => {
    if (def.headerName === 'LoanNumberH' && def.field === 'loanNumber') def.hide = true;
  });

  return (
    <Grid>
      <GridFilters 
        gridOptions={gridOptions}
        selectedTab={selectedTab}
        gridRef={gridRef}
        filterRef={filterRef}
        tableName={tableName} 
        allFilters={allFilters}
        currFilter={currFilter}
        setCurrFilter={setCurrFilter}
        canSaveFilter={canSaveFilter}
        setCanSaveFilter={setCanSaveFilter}
        canDeleteFilter={canDeleteFilter}
        setCanDeleteFilter={setCanDeleteFilter}
      />

      <AgGridReact
        {...props}
        ref={gridRef}
        columnDefs={columnDefs}
        gridOptions={gridOptions}
        components={gridComponents}
        icons={icons}
        sideBar={sideBar}
        onColumnVisible={onColumnVisible}
        onFirstDataRendered={onFirstDataRendered}
        defaultColDef={{ resizable: true }}
      />
      <Floating>{floatingButton}</Floating>
    </Grid>
  );
}

export default StandardGrid;