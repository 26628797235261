import React, { useState, useEffect, useContext } from "react";
import { getInitials } from "../helpers";
import classNames from "classnames";
import {
  Box,
  Tabs,
  Tab,
  Badge,
  CircularProgress,
  Stack,
} from '@mui/material';
import { useQuery } from "@apollo/client";
import { MY_TASKS_QUERY } from "../apollo/queries/myTasksQuery";
import { GET_TEAMS } from "../apollo/queries/getTeams";
import { UserContext } from "../context/UserContext";
import TeamAssetList from "../components/TeamAssetList/TeamAssetList";
import AssetReferralTable from "../components/AssetReferralTable/AssetReferralTable";
import AssignedTasks from "../components/AssignedTasks/AssignedTasks";
import Button from "../styled/Button/Button";
import { useTheme } from "@emotion/react";
import Typography from "../styled/Typography/Typography";

const hidden = {
  display: 'none',
  height: '100%',
}

const defaultStyle = {
  height: '100%',
}

export default function TeamAssets() {
  const [numTasks, setNumTasks] = useState(null);
  const [numReferrals, setNumReferrals] = useState(null);
  const [numAssets] = useState(null);
  const [selected, setSelected] = useState(null);
  const { setPortfolioID, setTeamID, user, setUser } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState(0);
  const [teams, setTeams] = useState([]);
  const theme = useTheme();

  const styles = {
    selected: {
      background: theme.themeColor.bodySecondary
    },
    innerBox: {
      background: theme.themeColor.bodyTetriary
    }
  }

  const { loading, data, error } = useQuery(GET_TEAMS, {
    variables: {
      input: {
        options: {
          memberships: { some: { userID: parseInt(user.id) } }
        }
      }
    }
  });

  const { loading: tasksLoading, data: tasksData } = useQuery(MY_TASKS_QUERY, {
    variables: { input: { assignments: { some: { id: parseInt(user.id) } } } },
    // pollInterval: parseInt(process.env.REACT_APP_BIG_POLL_INTERVAL),
  });

  useEffect(async () => {
    if (data?.findTeams.length > 0) {
      const validTeams = data.findTeams.filter((team) => !team.portfolio.deleted);
      if (validTeams.length > 0) {
        setTeams(validTeams);
        setSelected(validTeams[0]);
        setTeamID(validTeams[0].id)
        setPortfolioID(validTeams[0].portfolio.id);
      } else setUser({ ...user, noTeamsOrPortfolio: true })
    }
  }, [data]);

  const handleChange = (_, newValue) => {
    setSelectedTab(newValue);
  };
  if (user?.noTeamsOrPortfolio) return (
    <Typography component="h1" variant="h3" align="center" mt={50}>
      You do not currently belong to a team or portfolio.
    </Typography>
  )
  if (selected === null && !error) return null;
  if (loading) return <CircularProgress />;

  return (
    <Box sx={{ m: 3 }}>
      <Stack sx={{height: '80vh'}}>
        <div className="side-tabs-layout">
          <div className="side-tabs">
            {teams.map((team) => {
              return (
                <Button
                  aria-label={team.portfolio.name}
                  title={team.portfolio.name}
                  className={classNames(
                    "side-tab",
                      `${selected === team.id ? "selected" : ""}`
                  )}
                  key={team.id}
                  style={selected === team.id ? styles.selected : {}}
                  onClick={() => {
                    setSelected(team.id);
                    setTeamID(team.id);
                    setPortfolioID(team.portfolio.id);
                  }}
                >
                  <div style={styles.innerBox} className="inner-box">
                    {getInitials(team.portfolio.name)}
                    {numAssets && <Badge color="primary" badgeContent={numAssets} />}
                  </div>
                </Button>
              );
            })}
          </div>
          <Box sx={{ height: '80vh', border: `1px solid ${theme.themeColor.sectionStroke}`, display:'flex', 'flex-direction': 'column' }}>
            <Tabs value={selectedTab} onChange={handleChange}>
              <Tab label="Assets" />
              <Tab
                label={
                  <div>
                      Tasks{" "}
                    {numTasks > 0 && <Badge sx={{ml: 2}} badgeContent={numTasks} color="primary" />}
                  </div>
                }
              />
              <Tab
                label={
                  <div style={{width: "300px"}}>
                      Referrals{" "}
                    {numReferrals > 0 && <Badge sx={{ml: 2, mr: 1}} badgeContent={numReferrals} color="primary" />}
                  </div>
                }
              />
            </Tabs>

            <div style={selectedTab !== 0 ? hidden : defaultStyle}>
              <TeamAssetList team={selected} selectedTab={selectedTab} />
            </div>
            <div style={selectedTab !== 1 ? hidden : defaultStyle}>
              <AssignedTasks setNumTasks={setNumTasks} tasksLoading={tasksLoading} tasksData={tasksData} />
            </div>
            <div style={selectedTab !== 2 ? hidden : defaultStyle}>
              <AssetReferralTable teamID={selected?.id} user={user} setNumReferrals={setNumReferrals} />
            </div>
          </Box>
        </div>
      </Stack>
    </Box>
  );
}